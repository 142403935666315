import "../styles/all.scss";
import React, { useState } from "react";
import tw, { css as twCss } from "twin.macro";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Disclosure, Transition } from "@headlessui/react";
import { ClassNames } from "@emotion/react";
import { Helmet } from "react-helmet";
import { ChevronUpIcon } from "@heroicons/react/solid";

const FORM_URL =
  "https://lvddy23ong.execute-api.us-west-2.amazonaws.com/Prod/submitForm";

const spinning = twCss`
animation: spin 1s linear infinite;
@keyframes spin {
  0%   {transform: rotate(0deg);}
  100% {transform: rotate(360deg);}
}
`;

const Tribute = ({ data }) => {
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const [howKnow, setHowKnow] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    fetch(FORM_URL, {
      method: "POST",
      body: JSON.stringify({
        name,
        message,
        howKnowPete: howKnow,
      }),
    })
      .then(() => {
        setLoading(false);
        setSubmitted(true);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Helmet>
        <script
          defer
          src="https://use.fontawesome.com/releases/v5.3.1/js/all.js"
        ></script>
      </Helmet>
      <Navbar />
      <div tw="bg-dark-grey">
        <div tw="max-w-screen-xl mx-auto">
          <div tw="md:hidden">
            <div tw="grid w-full h-[calc(100vh - 130px)]">
              <StaticImage
                src="../img/pete-square.png"
                alt="Pete Newbon portrait"
                tw="grid-area[1/1]"
                quality={100}
                placeholder="blurred"
              />
              <div tw="grid grid-area[1/1]">
                <div tw="z-10 relative">
                  <h1
                    tw="text-dark-red z-10 p-6 text-4xl sm:text-5xl drop-shadow-2xl uppercase absolute left-0 bottom-8"
                    className="font-cb"
                  >
                    <i
                      className="fas fa-caret-down"
                      tw="text-dark-red text-lg absolute -mt-5 ml-1"
                    ></i>
                    A Tribute To Pete Newbon
                  </h1>
                </div>
              </div>
            </div>
            <div tw="px-6 py-12 text-center flex flex-col items-center w-full font-sans">
              <p tw="prose">
                Pete Newbon has been described as a “hidden saint” and his
                tragic death impacted many people. Please submit your tribute
                below, for posting on this page. You can contribute to his
                crowdfunder{" "}
                <a
                  href="https://gofund.me/66850ac3"
                  target="_blank"
                  rel="noopener noreferrer"
                  tw="text-dark-red hover:text-bright-red"
                >
                  here
                </a>
                .
              </p>
            </div>
          </div>
          <div tw="hidden md:flex pt-12 pb-6 justify-center">
            <StaticImage
              src="../img/pete-square.png"
              alt="Pete Newbon portrait"
              quality={100}
              placeholder="blurred"
              tw="max-w-sm rounded-l-sm"
            />
            <div tw="p-12 rounded-r-sm shadow-xl font-sans">
              <h1 tw="text-dark-red z-10 font-sans font-bold p-6 text-4xl drop-shadow-2xl text-center">
                A Tribute To Pete Newbon
              </h1>
              <p tw="prose">
                Pete Newbon has been described as a “hidden saint” and his
                tragic death impacted many people. Please submit your tribute
                below, for posting on this page. You can contribute to his
                crowdfunder{" "}
                <a
                  href="https://gofund.me/66850ac3"
                  target="_blank"
                  rel="noopener noreferrer"
                  tw="text-dark-red hover:text-bright-red"
                >
                  here
                </a>
                .
              </p>
            </div>
          </div>
          <div tw="py-12">
            <Disclosure>
              {({ open }) => (
                <>
                  <Disclosure.Button tw="mx-auto flex justify-around items-center w-64 px-4 py-2 font-sans text-lg text-left text-dark-red rounded hover:bg-dark-grey border-none bg-transparent">
                    <span>Add your message</span>
                    <ChevronUpIcon
                      tw="w-5 h-5 text-dark-red -mb-1"
                      css={[open && twCss({ transform: "rotateX(180deg)" })]}
                    />
                  </Disclosure.Button>
                  <ClassNames>
                    {({ css }) => (
                      <Transition
                        enter={css(tw`transition duration-300 ease-out`)}
                        enterFrom={css(tw`transform scale-95 opacity-0`)}
                        enterTo={css(tw`transform scale-100 opacity-100`)}
                        leave={css(tw`transition duration-75 ease-out`)}
                        leaveFrom={css(tw`transform scale-100 opacity-100`)}
                        leaveTo={css(tw`transform scale-95 opacity-0`)}
                      >
                        <Disclosure.Panel>
                          <div tw="mx-auto max-w-prose">
                            {submitted ? (
                              <p tw="font-sans pt-4">
                                Thank you. Your message will be reviewed and
                                added to the list as soon as possible.
                              </p>
                            ) : (
                              <form
                                tw="flex flex-col space-y-8 font-sans pb-12 px-4 relative"
                                onSubmit={handleSubmit}
                              >
                                {loading && (
                                  <div
                                    tw="w-16 h-16 border-4 border-dark-red border-t-transparent border-solid rounded-full absolute top-1/2 left-1/2 transform -translate-y-1/2! -translate-x-1/2!"
                                    css={[spinning]}
                                  />
                                )}
                                <fieldset>
                                  <label
                                    htmlFor="name"
                                    tw="block text-sm font-medium text-gray-700"
                                  >
                                    Your name
                                  </label>
                                  <div tw="mt-1">
                                    <input
                                      type="name"
                                      name="name"
                                      id="name"
                                      tw="shadow-sm block w-full sm:text-sm border-none rounded p-3 focus:ring-black"
                                      placeholder="Your name"
                                      required
                                      onChange={(e) => setName(e.target.value)}
                                    />
                                  </div>
                                </fieldset>
                                <fieldset>
                                  <label
                                    htmlFor="message"
                                    tw="block text-sm font-medium text-gray-700"
                                  >
                                    Message
                                  </label>
                                  <div tw="mt-1">
                                    <textarea
                                      rows={4}
                                      name="message"
                                      id="message"
                                      tw="shadow-sm block w-full sm:text-sm border-none rounded p-3 focus:ring-black"
                                      required
                                      onChange={(e) =>
                                        setMessage(e.target.value)
                                      }
                                    />
                                  </div>
                                </fieldset>
                                <fieldset>
                                  <label
                                    htmlFor="how-know"
                                    tw="block text-sm font-medium text-gray-700"
                                  >
                                    How did you know Pete? (Optional)
                                  </label>
                                  <div tw="mt-1">
                                    <input
                                      type="text"
                                      name="how-know"
                                      id="how-know"
                                      tw="shadow-sm block w-full sm:text-sm border-none rounded p-3 focus:ring-black"
                                      onChange={(e) =>
                                        setHowKnow(e.target.value)
                                      }
                                    />
                                  </div>
                                </fieldset>
                                <button
                                  type="submit"
                                  disabled={loading}
                                  tw="bg-white p-3 border-none rounded shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50"
                                >
                                  Submit
                                </button>
                              </form>
                            )}
                          </div>
                        </Disclosure.Panel>
                      </Transition>
                    )}
                  </ClassNames>
                </>
              )}
            </Disclosure>
          </div>
          <div tw="pb-12 border-t border-t-black px-6">
            <div tw="space-y-8 lg:space-y-12 flex flex-col max-w-prose mx-auto">
              {data.tributes.frontmatter.tributes.map(
                ({ tribute_: { name, message, howKnow } }) => (
                  <div key={name} tw="border-b border-black">
                    <p tw="text-xs italic text-gray-900">{`- Posted by ${name}`}</p>
                    {!!howKnow && (
                      <div tw="font-sans border border-transparent border-b-black">
                        <span tw="block text-sm text-gray-700">
                          How did you know Pete?
                        </span>
                        <p>{howKnow}</p>
                      </div>
                    )}
                    <p
                      tw="font-sans leading-relaxed whitespace-pre-wrap"
                      dangerouslySetInnerHTML={{ __html: message }}
                    />
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Tribute;

export const pageQuery = graphql`
  query PetePage {
    tributes: markdownRemark(
      frontmatter: { templateKey: { eq: "pete-page" } }
    ) {
      frontmatter {
        tributes {
          tribute_ {
            name
            message
            howKnow
          }
        }
      }
    }
  }
`;
